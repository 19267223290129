import * as React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout/layout.fr";

const DivisionPage = ({ pageContext, data }) => {
  // const divisions = pageContext.division;
  const title = pageContext.title;
  const slug = pageContext.slug;
  // const content = pageContext.french;
  const divisionName = pageContext.french.division_name;
  var todayDate = new Date().toISOString().slice(0, 10);
  const languageToggle = pageContext.languageToggle;
  // const isBrowser = typeof window !== "undefined";
  let alertsExist = null;

  if (data.allJson.edges === undefined || data.allJson.edges.length === 0) {
    alertsExist = false;
  } else {
    alertsExist = true;
  }

  switch (alertsExist) {
    case false:
      return (
        <Layout siteTitle={`${title} - GRC.ca`} slug={slug} divisionName={divisionName} languageToggle={languageToggle} nav="division">
          <main className="container" property="mainContentOfPage" resource="#wb-main" typeof="WebPageElement">
            <h1 id="wb-cont" property="name">
              {title}
            </h1>
            <div class="alert alert-success">
              <p>Aucune alerte d'urgence active n'a été émise.</p>
            </div>
            <div className="pagedetails">
              <dl id="wb-dtmd">
                <dt>Date de modification&#160;:&#32;</dt>
                <dd>
                  <time property="dateModified">{todayDate}</time>
                </dd>
              </dl>
            </div>
          </main>
        </Layout>
      );
    case true:
      return (
        <Layout siteTitle={`${title} - GRC.ca`} slug={slug} divisionName={divisionName} languageToggle={languageToggle} nav="division">
          <main className="container" property="mainContentOfPage" resource="#wb-main" typeof="WebPageElement">
            <h1 id="wb-cont" property="name">
              {title}
            </h1>
            {data.allJson.edges.map(({ node }, i) => (
              <div key={i} className="list-group">
                <Link to={node.french.url} className="list-group-item">
                  {node.french.location} - {node.french.alert_type}
                </Link>
              </div>
            ))}
            <div className="pagedetails">
              <dl id="wb-dtmd">
                <dt>Date de modification&#160;:&#32;</dt>
                <dd>
                  <time property="dateModified">{todayDate}</time>
                </dd>
              </dl>
            </div>
          </main>
        </Layout>
      );
    default:
      return null;
  }
};

export const query = graphql`
  query ($slug: String!) {
    allJson(filter: { division_slug: { eq: $slug }, division_json: { ne: true } }) {
      edges {
        node {
          division_slug
          alert_id
          division
          alert_json
          french {
            alert_type
            location
            url
            wysiwyg
          }
        }
      }
    }
  }
`;

export default DivisionPage;
